<template>
  <!-- generic select for archetypes in the Graph as License, Partner etc -->

  <section>
    HSELECT!
    <q-select
      v-model="license"
      filled
      fill-input
      :options="items"
      :label="label"
      option-label="name"
      option-value="uuid"
      @input="$emit('input', license)"
    >
      <template v-slot:selected v-if="license">
        {{ license.code }}
      </template>

      <template v-slot:option="scope">
        <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">
          {{ scope.opt.code }} &mdash; {{ scope.opt.name }}
        </q-item>
      </template>
    </q-select>
  </section>
</template>
<script>
import { API } from '../tic'
export default {
  name: 'HSelect',
  props: {
    type: {
      type: String,
      default: ''
    },

    value: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      items: [],
      license: null,
    }
  },
  computed:  {
    label: function() {
      let l = this.type.charAt(0).toUpperCase() + this.type.slice(1)
      console.log("llll: ", l)
      return this.type ? `Select ${l}` : 'h-select: no type set'
    }
  },

  mounted() {
    // get License entries from Graph
    API.get('/select/copyright')
      .then((r) => {
        this.items = [...r.data]
      })
      .then(() => {
        this.license = this.value
      })
  },
}
</script>
