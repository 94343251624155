<template>
  <q-btn
    style="margin-top: -4px"
    dense
    round
    flat
    color="primary"
    icon="fas fa-plus"
    @click="addbutton()"
  />
</template>
<script>
export default {
  name: "add-button",
  methods: {
    addbutton() {
      this.$emit("add");
    },
  },
};
</script>
